@font-face {
  font-family: "Encode Sans Condensed";
  src: url("../../public/fonts/EncodeSansCondensed-ExtraLight.eot");
  src: url("../../public/fonts/EncodeSansCondensed-ExtraLight.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansCondensed-ExtraLight.woff2")
      format("woff2"),
    url("../../public/fonts/EncodeSansCondensed-ExtraLight.woff") format("woff"),
    url("../../public/fonts/EncodeSansCondensed-ExtraLight.ttf")
      format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans Condensed";
  src: url("../../public/fonts/EncodeSansCondensed-Bold.eot");
  src: url("../../public/fonts/EncodeSansCondensed-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansCondensed-Bold.woff2") format("woff2"),
    url("../../public/fonts/EncodeSansCondensed-Bold.woff") format("woff"),
    url("../../public/fonts/EncodeSansCondensed-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans";
  src: url("../../public/fonts/EncodeSans-Bold.eot");
  src: url("../../public/fonts/EncodeSans-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSans-Bold.woff2") format("woff2"),
    url("../../public/fonts/EncodeSans-Bold.woff") format("woff"),
    url("../../public/fonts/EncodeSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans Condensed";
  src: url("../../public/fonts/EncodeSansCondensed-Black.eot");
  src: url("../../public/fonts/EncodeSansCondensed-Black.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansCondensed-Black.woff2") format("woff2"),
    url("../../public/fonts/EncodeSansCondensed-Black.woff") format("woff"),
    url("../../public/fonts/EncodeSansCondensed-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans";
  src: url("../../public/fonts/EncodeSans-Black.eot");
  src: url("../../public/fonts/EncodeSans-Black.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSans-Black.woff2") format("woff2"),
    url("../../public/fonts/EncodeSans-Black.woff") format("woff"),
    url("../../public/fonts/EncodeSans-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans Condensed";
  src: url("../../public/fonts/EncodeSansCondensed-ExtraBold.eot");
  src: url("../../public/fonts/EncodeSansCondensed-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansCondensed-ExtraBold.woff2")
      format("woff2"),
    url("../../public/fonts/EncodeSansCondensed-ExtraBold.woff") format("woff"),
    url("../../public/fonts/EncodeSansCondensed-ExtraBold.ttf")
      format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans Expanded";
  src: url("../../public/fonts/EncodeSansExpanded-Bold.eot");
  src: url("../../public/fonts/EncodeSansExpanded-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansExpanded-Bold.woff2") format("woff2"),
    url("../../public/fonts/EncodeSansExpanded-Bold.woff") format("woff"),
    url("../../public/fonts/EncodeSansExpanded-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans SemiCondensed";
  src: url("../../public/fonts/EncodeSansSemiCondensed-Black.eot");
  src: url("../../public/fonts/EncodeSansSemiCondensed-Black.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansSemiCondensed-Black.woff2")
      format("woff2"),
    url("../../public/fonts/EncodeSansSemiCondensed-Black.woff") format("woff"),
    url("../../public/fonts/EncodeSansSemiCondensed-Black.ttf")
      format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans";
  src: url("../../public/fonts/EncodeSans-ExtraBold.eot");
  src: url("../../public/fonts/EncodeSans-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSans-ExtraBold.woff2") format("woff2"),
    url("../../public/fonts/EncodeSans-ExtraBold.woff") format("woff"),
    url("../../public/fonts/EncodeSans-ExtraBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans SemiCondensed";
  src: url("../../public/fonts/EncodeSansSemiCondensed-Bold.eot");
  src: url("../../public/fonts/EncodeSansSemiCondensed-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansSemiCondensed-Bold.woff2") format("woff2"),
    url("../../public/fonts/EncodeSansSemiCondensed-Bold.woff") format("woff"),
    url("../../public/fonts/EncodeSansSemiCondensed-Bold.ttf")
      format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans Expanded";
  src: url("../../public/fonts/EncodeSansExpanded-Black.eot");
  src: url("../../public/fonts/EncodeSansExpanded-Black.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansExpanded-Black.woff2") format("woff2"),
    url("../../public/fonts/EncodeSansExpanded-Black.woff") format("woff"),
    url("../../public/fonts/EncodeSansExpanded-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans Condensed";
  src: url("../../public/fonts/EncodeSansCondensed-Thin.eot");
  src: url("../../public/fonts/EncodeSansCondensed-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansCondensed-Thin.woff2") format("woff2"),
    url("../../public/fonts/EncodeSansCondensed-Thin.woff") format("woff"),
    url("../../public/fonts/EncodeSansCondensed-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans Condensed";
  src: url("../../public/fonts/EncodeSansCondensed-SemiBold.eot");
  src: url("../../public/fonts/EncodeSansCondensed-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansCondensed-SemiBold.woff2") format("woff2"),
    url("../../public/fonts/EncodeSansCondensed-SemiBold.woff") format("woff"),
    url("../../public/fonts/EncodeSansCondensed-SemiBold.ttf")
      format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans Condensed";
  src: url("../../public/fonts/EncodeSansCondensed-Regular.eot");
  src: url("../../public/fonts/EncodeSansCondensed-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansCondensed-Regular.woff2") format("woff2"),
    url("../../public/fonts/EncodeSansCondensed-Regular.woff") format("woff"),
    url("../../public/fonts/EncodeSansCondensed-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans Expanded";
  src: url("../../public/fonts/EncodeSansExpanded-Thin.eot");
  src: url("../../public/fonts/EncodeSansExpanded-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansExpanded-Thin.woff2") format("woff2"),
    url("../../public/fonts/EncodeSansExpanded-Thin.woff") format("woff"),
    url("../../public/fonts/EncodeSansExpanded-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans Expanded";
  src: url("../../public/fonts/EncodeSansExpanded-Light.eot");
  src: url("../../public/fonts/EncodeSansExpanded-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansExpanded-Light.woff2") format("woff2"),
    url("../../public/fonts/EncodeSansExpanded-Light.woff") format("woff"),
    url("../../public/fonts/EncodeSansExpanded-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans";
  src: url("../../public/fonts/EncodeSans-Medium.eot");
  src: url("../../public/fonts/EncodeSans-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSans-Medium.woff2") format("woff2"),
    url("../../public/fonts/EncodeSans-Medium.woff") format("woff"),
    url("../../public/fonts/EncodeSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans Condensed";
  src: url("../../public/fonts/EncodeSansCondensed-Medium.eot");
  src: url("../../public/fonts/EncodeSansCondensed-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansCondensed-Medium.woff2") format("woff2"),
    url("../../public/fonts/EncodeSansCondensed-Medium.woff") format("woff"),
    url("../../public/fonts/EncodeSansCondensed-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans Expanded";
  src: url("../../public/fonts/EncodeSansExpanded-SemiBold.eot");
  src: url("../../public/fonts/EncodeSansExpanded-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansExpanded-SemiBold.woff2") format("woff2"),
    url("../../public/fonts/EncodeSansExpanded-SemiBold.woff") format("woff"),
    url("../../public/fonts/EncodeSansExpanded-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans Expanded";
  src: url("../../public/fonts/EncodeSansExpanded-ExtraLight.eot");
  src: url("../../public/fonts/EncodeSansExpanded-ExtraLight.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansExpanded-ExtraLight.woff2")
      format("woff2"),
    url("../../public/fonts/EncodeSansExpanded-ExtraLight.woff") format("woff"),
    url("../../public/fonts/EncodeSansExpanded-ExtraLight.ttf")
      format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans Expanded";
  src: url("../../public/fonts/EncodeSansExpanded-ExtraBold.eot");
  src: url("../../public/fonts/EncodeSansExpanded-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansExpanded-ExtraBold.woff2") format("woff2"),
    url("../../public/fonts/EncodeSansExpanded-ExtraBold.woff") format("woff"),
    url("../../public/fonts/EncodeSansExpanded-ExtraBold.ttf")
      format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans SemiCondensed";
  src: url("../../public/fonts/EncodeSansSemiCondensed-ExtraBold.eot");
  src: url("../../public/fonts/EncodeSansSemiCondensed-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansSemiCondensed-ExtraBold.woff2")
      format("woff2"),
    url("../../public/fonts/EncodeSansSemiCondensed-ExtraBold.woff")
      format("woff"),
    url("../../public/fonts/EncodeSansSemiCondensed-ExtraBold.ttf")
      format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans Expanded";
  src: url("../../public/fonts/EncodeSansExpanded-Medium.eot");
  src: url("../../public/fonts/EncodeSansExpanded-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansExpanded-Medium.woff2") format("woff2"),
    url("../../public/fonts/EncodeSansExpanded-Medium.woff") format("woff"),
    url("../../public/fonts/EncodeSansExpanded-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans";
  src: url("../../public/fonts/EncodeSans-ExtraLight.eot");
  src: url("../../public/fonts/EncodeSans-ExtraLight.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSans-ExtraLight.woff2") format("woff2"),
    url("../../public/fonts/EncodeSans-ExtraLight.woff") format("woff"),
    url("../../public/fonts/EncodeSans-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans Condensed";
  src: url("../../public/fonts/EncodeSansCondensed-Light.eot");
  src: url("../../public/fonts/EncodeSansCondensed-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansCondensed-Light.woff2") format("woff2"),
    url("../../public/fonts/EncodeSansCondensed-Light.woff") format("woff"),
    url("../../public/fonts/EncodeSansCondensed-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans";
  src: url("../../public/fonts/EncodeSans-SemiBold.eot");
  src: url("../../public/fonts/EncodeSans-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSans-SemiBold.woff2") format("woff2"),
    url("../../public/fonts/EncodeSans-SemiBold.woff") format("woff"),
    url("../../public/fonts/EncodeSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans";
  src: url("../../public/fonts/EncodeSans-Light.eot");
  src: url("../../public/fonts/EncodeSans-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSans-Light.woff2") format("woff2"),
    url("../../public/fonts/EncodeSans-Light.woff") format("woff"),
    url("../../public/fonts/EncodeSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans SemiCondensed";
  src: url("../../public/fonts/EncodeSansSemiCondensed-ExtraLight.eot");
  src: url("../../public/fonts/EncodeSansSemiCondensed-ExtraLight.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansSemiCondensed-ExtraLight.woff2")
      format("woff2"),
    url("../../public/fonts/EncodeSansSemiCondensed-ExtraLight.woff")
      format("woff"),
    url("../../public/fonts/EncodeSansSemiCondensed-ExtraLight.ttf")
      format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans Expanded";
  src: url("../../public/fonts/EncodeSansExpanded-Regular.eot");
  src: url("../../public/fonts/EncodeSansExpanded-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansExpanded-Regular.woff2") format("woff2"),
    url("../../public/fonts/EncodeSansExpanded-Regular.woff") format("woff"),
    url("../../public/fonts/EncodeSansExpanded-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans";
  src: url("../../public/fonts/EncodeSans-Regular.eot");
  src: url("../../public/fonts/EncodeSans-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSans-Regular.woff2") format("woff2"),
    url("../../public/fonts/EncodeSans-Regular.woff") format("woff"),
    url("../../public/fonts/EncodeSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans SemiExpanded";
  src: url("../../public/fonts/EncodeSansSemiExpanded-Bold.eot");
  src: url("../../public/fonts/EncodeSansSemiExpanded-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansSemiExpanded-Bold.woff2") format("woff2"),
    url("../../public/fonts/EncodeSansSemiExpanded-Bold.woff") format("woff"),
    url("../../public/fonts/EncodeSansSemiExpanded-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans SemiCondensed";
  src: url("../../public/fonts/EncodeSansSemiCondensed-Regular.eot");
  src: url("../../public/fonts/EncodeSansSemiCondensed-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansSemiCondensed-Regular.woff2")
      format("woff2"),
    url("../../public/fonts/EncodeSansSemiCondensed-Regular.woff")
      format("woff"),
    url("../../public/fonts/EncodeSansSemiCondensed-Regular.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans SemiExpanded";
  src: url("../../public/fonts/EncodeSansSemiExpanded-Thin.eot");
  src: url("../../public/fonts/EncodeSansSemiExpanded-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansSemiExpanded-Thin.woff2") format("woff2"),
    url("../../public/fonts/EncodeSansSemiExpanded-Thin.woff") format("woff"),
    url("../../public/fonts/EncodeSansSemiExpanded-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans SemiExpanded";
  src: url("../../public/fonts/EncodeSansSemiExpanded-SemiBold.eot");
  src: url("../../public/fonts/EncodeSansSemiExpanded-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansSemiExpanded-SemiBold.woff2")
      format("woff2"),
    url("../../public/fonts/EncodeSansSemiExpanded-SemiBold.woff")
      format("woff"),
    url("../../public/fonts/EncodeSansSemiExpanded-SemiBold.ttf")
      format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans";
  src: url("../../public/fonts/EncodeSans-Thin.eot");
  src: url("../../public/fonts/EncodeSans-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSans-Thin.woff2") format("woff2"),
    url("../../public/fonts/EncodeSans-Thin.woff") format("woff"),
    url("../../public/fonts/EncodeSans-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans SemiCondensed";
  src: url("../../public/fonts/EncodeSansSemiCondensed-Medium.eot");
  src: url("../../public/fonts/EncodeSansSemiCondensed-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansSemiCondensed-Medium.woff2")
      format("woff2"),
    url("../../public/fonts/EncodeSansSemiCondensed-Medium.woff") format("woff"),
    url("../../public/fonts/EncodeSansSemiCondensed-Medium.ttf")
      format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans SemiExpanded";
  src: url("../../public/fonts/EncodeSansSemiExpanded-Light.eot");
  src: url("../../public/fonts/EncodeSansSemiExpanded-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansSemiExpanded-Light.woff2") format("woff2"),
    url("../../public/fonts/EncodeSansSemiExpanded-Light.woff") format("woff"),
    url("../../public/fonts/EncodeSansSemiExpanded-Light.ttf")
      format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans SemiCondensed";
  src: url("../../public/fonts/EncodeSansSemiCondensed-Light.eot");
  src: url("../../public/fonts/EncodeSansSemiCondensed-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansSemiCondensed-Light.woff2")
      format("woff2"),
    url("../../public/fonts/EncodeSansSemiCondensed-Light.woff") format("woff"),
    url("../../public/fonts/EncodeSansSemiCondensed-Light.ttf")
      format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans SemiExpanded";
  src: url("../../public/fonts/EncodeSansSemiExpanded-ExtraBold.eot");
  src: url("../../public/fonts/EncodeSansSemiExpanded-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansSemiExpanded-ExtraBold.woff2")
      format("woff2"),
    url("../../public/fonts/EncodeSansSemiExpanded-ExtraBold.woff")
      format("woff"),
    url("../../public/fonts/EncodeSansSemiExpanded-ExtraBold.ttf")
      format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans SemiCondensed";
  src: url("../../public/fonts/EncodeSansSemiCondensed-Thin.eot");
  src: url("../../public/fonts/EncodeSansSemiCondensed-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansSemiCondensed-Thin.woff2") format("woff2"),
    url("../../public/fonts/EncodeSansSemiCondensed-Thin.woff") format("woff"),
    url("../../public/fonts/EncodeSansSemiCondensed-Thin.ttf")
      format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans SemiExpanded";
  src: url("../../public/fonts/EncodeSansSemiExpanded-Medium.eot");
  src: url("../../public/fonts/EncodeSansSemiExpanded-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansSemiExpanded-Medium.woff2")
      format("woff2"),
    url("../../public/fonts/EncodeSansSemiExpanded-Medium.woff") format("woff"),
    url("../../public/fonts/EncodeSansSemiExpanded-Medium.ttf")
      format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans SemiExpanded";
  src: url("../../public/fonts/EncodeSansSemiExpanded-ExtraLight.eot");
  src: url("../../public/fonts/EncodeSansSemiExpanded-ExtraLight.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansSemiExpanded-ExtraLight.woff2")
      format("woff2"),
    url("../../public/fonts/EncodeSansSemiExpanded-ExtraLight.woff")
      format("woff"),
    url("../../public/fonts/EncodeSansSemiExpanded-ExtraLight.ttf")
      format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans SemiExpanded";
  src: url("../../public/fonts/EncodeSansSemiExpanded-Regular.eot");
  src: url("../../public/fonts/EncodeSansSemiExpanded-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansSemiExpanded-Regular.woff2")
      format("woff2"),
    url("../../public/fonts/EncodeSansSemiExpanded-Regular.woff") format("woff"),
    url("../../public/fonts/EncodeSansSemiExpanded-Regular.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans SemiExpanded";
  src: url("../../public/fonts/EncodeSansSemiExpanded-Black.eot");
  src: url("../../public/fonts/EncodeSansSemiExpanded-Black.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansSemiExpanded-Black.woff2") format("woff2"),
    url("../../public/fonts/EncodeSansSemiExpanded-Black.woff") format("woff"),
    url("../../public/fonts/EncodeSansSemiExpanded-Black.ttf")
      format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Encode Sans SemiCondensed";
  src: url("../../public/fonts/EncodeSansSemiCondensed-SemiBold.eot");
  src: url("../../public/fonts/EncodeSansSemiCondensed-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../../public/fonts/EncodeSansSemiCondensed-SemiBold.woff2")
      format("woff2"),
    url("../../public/fonts/EncodeSansSemiCondensed-SemiBold.woff")
      format("woff"),
    url("../../public/fonts/EncodeSansSemiCondensed-SemiBold.ttf")
      format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
